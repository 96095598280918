<template>
  <div id="app">
    <div class="container">
      <el-button type="success" style="margin: 0 0 10px 0" @click="classify">设备类型分类</el-button>

      <el-dialog title="设备类型分类" :visible.sync="dialogVisible" width="45%" style="background:rgba(0,0,0,0.5)">
        <div class="content">
          <div class="item">
            <div>
              <b>类型名称：</b>
              <el-input :disabled="edit" v-model="classifyList.name" style="width:90%;margin-right:10px" placeholder="请输入分类类型名称"></el-input>
            </div>
          </div>
          <div class="item">
            <b>设备类型：</b>
            <el-select v-model="classifyList.valueList" style="width:90%" filterable placeholder="请选择设备类型" multiple clearable collapse-tags @change="selectDeviceType">
              <el-option v-for="item in typeList1" :key="item.id" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </div>
          <div class="item-box">
            <div>
              <el-tag type="primary" v-for="(item,index) in classifyList.valueList" closable @close="closeTag(index)" style="margin:5px 10px">{{item}}</el-tag>
            </div>

          </div>
        </div>
        <div slot="footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitInfo">确 定</el-button>
        </div>
      </el-dialog>

      <el-table :data="typeClassifyList" style="width: 100%;border-radius:5px" max-height="700">
        <el-table-column type="index" label="序号" width="70" align="center">
        </el-table-column>
        <el-table-column prop="name" label="类型名称" width="120" align="center">
        </el-table-column>
        <el-table-column label="类型名称" align="center">
          <template slot-scope="scope">
            <el-tag type="primary" style="margin:0 10px" v-for="(item, index) in scope.row.valueList">{{item}}</el-tag>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button @click="editRow(scope.row)" type="primary" size="small">编辑</el-button>
            <el-button @click="deleteRow(scope.row.name)" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
      <!-- <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" style="padding-top: 10px;  text-align: center" @current-change="pagination"></el-pagination> -->

    </div>
  </div>
</template>

<script>
import DeviceTypeManagement from '@/api/manageApi/DeviceTypeManagement'
export default {
  data() {
    return {
      dialogVisible: false,
      edit: false,
      deviceTypeName: '',  // 搜索设备类型
      typeList: [], // 所有设备类型
      typeList1: [],
      limit: 20,
      current: 1,
      total: 0,
      classifyList: {},
      typeClassifyList: []
    }
  },
  created() {
  },
  mounted() {
    this.getAllDevicecType()
  },
  methods: {
    async getAllDevicecType() {
      const { data: typeList } = await DeviceTypeManagement.getAllDeviceType();
      console.log(typeList);
      this.typeList = typeList.data.list
      this.init()
    },
    // 初始化查询所有设备信息
    async init() {
      // 分页查设备类型分类
      const { data: typeClassifyList } = await DeviceTypeManagement.pageDeviceTypeClassify(this.current, this.limit)
      this.typeClassifyList = typeClassifyList.data.list
      this.total = typeClassifyList.data.total

      if (this.typeClassifyList.name != '' || this.typeClassifyList.name != null) {
        for (let i = 0; i < this.typeClassifyList.length; i++) {
          for (let j = 0; j < this.typeClassifyList[i].valueList.length; j++) {
            for (let k = 0; k < this.typeList.length; k++) {
              if (this.typeClassifyList[i].valueList[j] == this.typeList[k].id) {
                this.typeClassifyList[i].valueList[j] = this.typeList[k].name
              }
            }
          }
        }
      }
    },
    pagination(val) {
      this.current = val
      this.init()
    },


    selectDeviceType(val) {
      this.classifyList.valueList = val
    },

    closeTag(i) {
      this.classifyList.valueList.splice(i, 1)
    },
    async classify(val) {
      this.edit = false
      this.classifyList = {}
      this.dialogVisible = true
      this.getNoBingdeviceType()
    },

    async getNoBingdeviceType() {
      const { data: typeList1 } = await DeviceTypeManagement.getNoBindDeviceTypeList();
      this.typeList1 = typeList1.data.list
      console.log(this.typeList1);
    },

    editRow(val) {
      this.edit = true
      let obj = JSON.stringify(val)
      this.classifyList = JSON.parse(obj)
      this.dialogVisible = true
      this.getNoBingdeviceType()
    },
    deleteRow(name) {
      this.$confirm('确认要删除这条记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeviceTypeManagement.deleteDeviceClassify(name).then(res => {
          if (res.data.message == "删除成功") {
            this.$message.success("删除成功")
            this.init()
          }
        })
      })
    },

    submitInfo() {
      for (let i = 0; i < this.classifyList.valueList.length; i++) {
        for (let j = 0; j < this.typeList.length; j++) {
          if (this.classifyList.valueList[i] == this.typeList[j].name) {
            this.classifyList.valueList[i] = this.typeList[j].id
          }
        }
      }
      this.$confirm('确认要提交此信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeviceTypeManagement.addDeviceTypeClassify(this.classifyList).then(res => {
          if (res.data.message == "添加成功") {
            if (this.edit) {
              this.$message.success("修改成功")
              this.edit = false
            } else {
              this.$message.success("添加成功")
            }
            this.init()
            this.dialogVisible = false
          }
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  padding: 10px 0;
  height: 100%;

  .content {
    height: 360px;

    .item {
      margin-bottom: 10px;
    }

    .item-box {
      border-top: 1px solid #ccc;
      width: 100%;
      height: 260px;
    }
  }
}
</style>